import React, { useEffect, useState, useRef } from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Switch,
    Spin,
    Form,
    Tooltip,
    Input,
    Popconfirm, InputNumber,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled,
} from "@ant-design/icons";
import { convertColumns } from "../../../../utils/columnconverter";
import { notify } from "../../../../redux/actions";
import { connect } from "react-redux";
import admin from "../../../../const/api";
import { useTranslation } from "react-i18next";
import {noWhitespace, whiteSpace} from "../../../../utils/rules";
import {apiRoutes} from "../../../../const/apiroutes";

const HomeStatistics = (props) => {
    const mainUrl = apiRoutes.projects.generalStatistics
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [positions, setPositions] = useState([]);
    const [spin, setSpin] = useState(false);

    let data = [
        {
            name:'Təmizlənən ərazi (illik)',
            key:'clean_area_year'
        },
        {
            name:'Təmizlənən ərazi (aylıq)',
            key:'clean_area_monthly'
        },
        {
            name:'Təmizlənən ərazi (heftelik)',
            key:'clean_area_week'
        },

        {
            name:'Tank əleyhinə mina (illik)',
            key:'tank_year'
        },
        {
            name:'Tank əleyhinə mina  (aylıq)',
            key:'tank_monthly'
        },
        {
            name:'Tank əleyhinə mina (heftelik)',
            key:'tank_week'
        },
        {
            name:'Partlamanayan hərbi sursat (illik)',
            key:'unexplosive_year'
        },
        {
            name:'Partlamanayan hərbi sursat  (aylıq)',
            key:'unexplosive_monthly'
        },
        {
            name:'Partlamanayan hərbi sursat (heftelik)',
            key:'unexplosive_week'
        },

        {
            name:'Piyada əleyhinə mina (illik)',
            key:'pedestrian_year'
        },
        {
            name:'Piyada əleyhinə mina  (aylıq)',
            key:'pedestrian_monthly'
        },
        {
            name:'Piyada əleyhinə mina (heftelik)',
            key:'pedestrian_week'
        },

    ]


    //     "pedestrian_year": 1,
    //     "pedestrian_week": 2,
    //     "pedestrian_monthly": 3,


    const nameInput = useRef();

    // props
    const { notify } = props;


    const setEditingObject = async () => {
            form.setFieldsValue(positions);
    };

    const cancelEditing = () => {
        form.resetFields();
    };


    const savePosition = async (values) => {
        let obj = {
          ...values
        };
        await admin
            .post(mainUrl, obj)
            .then((res) => {
                notify("", true);
                getPositions();
                cancelEditing();
            })
            .catch((err) => {
                notify(err.response, false);
            });
    };

    const getPositions = async () => {
        setSpin(true);
        await admin.get(mainUrl).then((res) => {
            setSpin(false);
            setPositions(res.data.content)
        });
    };

    useEffect(() => {
        getPositions();
    }, [t]);

    return (
        <Row gutter={[10, 10]}>
            <Col lg={12} xs={24}>
                {spin ?
                    <div className="flex animated fadeInUp bg-white all-center p-2">
                        <Spin size={"large"} />
                    </div>
                    :
                    <div className={'bg-white p-2'}>
                        <table className="customtable">
                            <tbody>
                            {data.map((s, i)=>(
                                <tr key={i}>
                                    <td>{s.name}</td>
                                    <td className={'text-right'}>{positions[s.key]}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <Button onClick={()=>{setEditingObject()}} className={'mt-5 w-100'} type={'primary'}>
                            {t('edit')}
                            <EditFilled/>
                        </Button>
                    </div>
                }
            </Col>
            <Col lg={12} xs={24}>
                <Card title={t("edit")} className={"animated fadeInRight"}>
                    <Form layout="vertical" onFinish={savePosition} form={form}>
                        <Row gutter={[16,16]}>
                            {data.map((s,  i)=>(
                                    <Col key={i} xs={24}  md={12}>
                                        <p className="mb-5">{s.name}</p>
                                        <div  className="form-lang">
                                            <Form.Item
                                                className="mb-5"
                                                validateTrigger="onChange"
                                                name={s.key}
                                                rules={[noWhitespace(t("inputError"))]}
                                            >
                                                <InputNumber ref={nameInput} />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>

                        <div className="flex  flex-between mt-15">
                            <Button onClick={cancelEditing}>{t("cancel")}</Button>
                            <Button htmlType="submit">{t("save")}</Button>
                        </div>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, { notify })(HomeStatistics);
