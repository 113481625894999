import React, { useEffect, useState, useRef } from "react";
import {
    Card,
    Table,
    Button,
    Spin,
    Form,
    Select,
    Row,
    Col,
    Tooltip,
    Input,
    Popconfirm, InputNumber, DatePicker,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled, EyeFilled, EyeOutlined,
} from "@ant-design/icons";
import { convertColumns } from "../../../../utils/columnconverter";
import { notify } from "../../../../redux/actions";
import { connect } from "react-redux";
import admin from "../../../../const/api";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {noWhitespace, whiteSpace} from "../../../../utils/rules";
import {apiRoutes} from "../../../../const/apiroutes";
import history from "../../../../const/history";
const {Option} = Select;

const Regions = (props) => {
    const {selectedAbouts ,setSelectedAbouts} = props
    const mainUrl = apiRoutes.projects.regions
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [positions, setPositions] = useState([]);
    const [spin, setSpin] = useState(false);
    const [editing, setEditing] = useState(null);
    const cols = [
        { key: "index", value: "#", con: true },
        { key: "data", value: 'Tarixi', con: true },
        { key: "abouts", value: 'Kontent', con: false },
        { key: "main", value: "", con: false },
    ];
    const nameInput = useRef();

    // props
    const { notify } = props;

    const columns = [
        {
            title: "#",
            key: "1",
            dataIndex: "index",
            width: 80,
        },
        {
            title: 'Adı',
            key: "2",
            dataIndex: "name",
        },
        {
            title: 'Kontent',
            key: "2",
            dataIndex: "id",
            render: (i) => {
                return (
                    <div>
                        <Button onClick={()=>{history.push(`/projects/regions/${i}`)}} type={'primary'}><EyeOutlined className={'mr-5'}/> Kontent</Button>
                    </div>
                )
            }
        },
        // {
        //     title: "",
        //     key: "3",
        //     dataIndex: "main",
        //     width: 30,
        //     render: (i) => {
        //         return (
        //             <div className="flex flex-end">
        //                 <Popconfirm
        //                     placement="topRight"
        //                     title={t("areYouSure")}
        //                     onConfirm={() => deletePosition(i.id)}
        //                     okText={t("yes")}
        //                     cancelText={t("no")}
        //                 >
        //                     <Tooltip className="ml-5" title={t("delete")}>
        //                         <Button className="border-none" type="text" shape="circle">
        //                             <DeleteFilled />
        //                         </Button>
        //                     </Tooltip>
        //                 </Popconfirm>
        //                 <Tooltip className="ml-5" title={t("edit")} placement="topRight">
        //                     <Button
        //                         className="border-none"
        //                         type="text"
        //                         shape="circle"
        //                         onClick={() => setEditingObject(i)}
        //                     >
        //                         <EditFilled />
        //                     </Button>
        //                 </Tooltip>
        //             </div>
        //         );
        //     },
        // },
    ];


    const setEditingObject = async (i) => {
        setEditing(i.id);
        let obj = {};
        let date = new Date(parseInt(i.date), 1, 1);
        obj['date'] = moment(date)
        form.setFieldsValue(obj);
    };

    const cancelEditing = () => {
        setEditing(null);
        form.resetFields();
    };

    const deletePosition = async (i) => {
        await admin
            .delete(`${mainUrl}/${i}`)
            .then(() => {
                notify("silindi", true);
                getPositions();
            })
            .catch((err) => {
                //error
                notify(err.response, false);
            });
    };

    const savePosition = async (values) => {
        let obj = {
            date:values.date._d.getFullYear()
        };
        if (!editing) {
            await admin
                .post(mainUrl, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            obj["id"] = editing;
            await admin
                .put(`/${mainUrl}/${editing}`, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };

    const getPositions = async () => {
        setSpin(true);
        await admin.get(mainUrl+'?per_page=4000').then((res) => {
            setSpin(false);
            setPositions(
                res.data.content.data.map((p, index) => {
                    return {
                        key: index + 1,
                        ...p,
                        index: index + 1,
                        main:{
                            id: p.id,
                            date:p.date
                        },
                        name: p.locales.find((l) => {
                            return l.local === localStorage.getItem("locale");
                        }).name,
                    };
                })
            );
        });
    };

    useEffect(() => {
        getPositions();
    }, [t]);

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border animated fadeInDown p-2 mt-0 bg-white">
                    <UnorderedListOutlined className="f-20 mr5-15" />
                    <span className="f-20 bold">Qarabağ regionlar</span>
                </div>
            </Col>
            <Col lg={24} xs={24}>
                <Table
                    loading={spin}
                    size="small"
                    className="bg-white animated fadeInLeft"
                    columns={columns}
                    dataSource={convertColumns(positions, cols)}
                    pagination={{
                        pageSize: 20,
                        current_page: 1,
                        total: positions.length,
                    }}
                />
            </Col>
            {/*<Col lg={12} xs={24}>*/}
            {/*    <Card title={t("addTo")} className={"animated fadeInRight"}>*/}
            {/*        <Form layout="vertical" onFinish={savePosition} form={form}>*/}
            {/*            <p className="mb-5">Adı</p>*/}
            {/*            <Form.Item*/}
            {/*                className="mb-5"*/}
            {/*                validateTrigger="onChange"*/}
            {/*                name={`date`}*/}
            {/*                rules={[noWhitespace(t("inputError"))]}*/}
            {/*            >*/}
            {/*                <DatePicker className={'w-100'} picker="year" />*/}
            {/*            </Form.Item>*/}

            {/*            <div className="flex  flex-between mt-15">*/}
            {/*                <Button onClick={cancelEditing}>{t("cancel")}</Button>*/}
            {/*                <Button htmlType="submit">{t("save")}</Button>*/}
            {/*            </div>*/}
            {/*        </Form>*/}
            {/*    </Card>*/}
            {/*</Col>*/}
        </Row>
    );
};

const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, { notify })(Regions);


