import React , {useState} from 'react';
import {UnorderedListOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {
    Row,
    Col,
    Button,
    Tabs,
} from "antd";

import Banners from "./Banners";
import Videos from "./Videos";
const { TabPane } = Tabs;

function Home(props) {
    const { t } = useTranslation();
    const [tabKey, setTabKey] = useState("1");

    const handleTabChange = (e) => {
        setTabKey(e);
    };

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border animated fadeInDown p-2 mt-0 bg-white">
                    <div className="flex flex-between">
                        <div>
                            <UnorderedListOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">Ana səhifə</span>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={24}>
                <div className="tab-section">
                    <Tabs
                        onChange={handleTabChange}
                        defaultActiveKey={tabKey}
                        className="bg-white w-100"
                    >
                        <TabPane tab={'Bannerlər'} key="1">
                            <div className="p-2">
                                <Banners  tabKey={tabKey} />
                            </div>
                        </TabPane>
                        <TabPane tab={'Videolar'} key="2">
                            <div className="p-2">
                                <Videos tabKey={tabKey} />
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </Col>
        </Row>
    );
}


export default Home;

