import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {
    Row,
    Col,
    Table,
    Button,
    Tooltip,
    Popconfirm,
    Modal,
    Spin, Select,
} from "antd";
import {
    EyeFilled,
    DeleteFilled,
    UsergroupAddOutlined,
} from "@ant-design/icons";
import moment from "moment";
import admin from "../../../../const/api";
import { notify } from "../../../../redux/actions";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../utils/columnconverter";
import { connect } from "react-redux";
import View from "./View/View";
import {apiRoutes} from "../../../../const/apiroutes";
const {Option} = Select
function Messages(props) {
    const mainUrl = apiRoutes.contact.contactForms
    const { t } = useTranslation();
    const [messages, setMessages] = useState([]);
    const [viewIndex, setViewIndex] = useState(null);
    const [visibleView, setVisibleView] = useState(false);
    const [spin, setSpin] = useState(false);
    let [trigger, setTrigger] = useState(0);
    const [category , setCategory] = useState(null)

    const cols = [
        { key: "tableIndex", value: "#", con: true },
        { key: "name", value: t("name"), con: true },
        { key: "email", value: t("email"), con: true },
        { key: "sendDate", value: "Göndərilmə tarixi", con: true },
        { key: "id", value: "", con: false },
        { key: "index", value: "", con: false },
    ];



    const initialColumns = [
        {
            title: "#",
            dataIndex: "tableIndex",
            key: "1",
            width: 60,
        },
        {
            title: t("name"),
            dataIndex: "full_name",
            key: "2",
        },
        {
            title: t("email"),
            dataIndex: "email",
            key: "3",
        },
        {
            title: "Göndərilmə tarixi",
            dataIndex: "sendDate",
            key: "4",
        },
        {
            title: t("Kategoriya"),
            dataIndex: "category",
            key: "5",
            render: (i) => {
                return (
                    <div>
                        {
                            i === '1' ?
                                <span className="green">Müraciət</span>
                                :
                            i === '2' ?
                                <span className="blue">Sual</span> :
                            i === '3' ?
                                <span className="red">Şikayət</span> : ''
                        }
                    </div>
                )
            }
        },
        {
            title: "",
            dataIndex: "index",
            key: "9",
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deleteMessage(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                        <Tooltip
                            className="ml-5"
                            title={t("detailed")}
                            placement="topRight"
                        >
                            <Button
                                className="border-none"
                                type="text"
                                shape="circle"
                                onClick={() => viewMessage(i)}
                            >
                                <EyeFilled />
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const { notify } = props;

    const viewMessage = (i) => {
        setViewIndex(i);
        setVisibleView(true);
    };

    const deleteMessage = async (i) => {
        if (i === 0 || i) {
            await admin
                .delete(`${mainUrl}/${messages[i].id}`)
                .then((res) => {
                    setTrigger(++trigger);
                    notify("silindi", true);
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };

    useEffect(() => {
        getUsers();
    }, [t, trigger , category]);

    const getUsers = () => {
        setSpin(true);
        admin.get(mainUrl , {params:{category: category ? category: null}}).then((res) => {
            res.data.content && setSpin(false);
            setMessages(
                res.data.content.map((d, index) => {
                    return {
                        ...d,
                        key: index + 1,
                        index,
                        tableIndex: index + 1,
                        sendDate: moment(d.created_at).format("DD-MM-YYYY HH:mm"),
                    };
                })
            );
        });
    };

    return (
        <div>
            <Row gutter={[10, 10]}>
                <Col xs={24}>
                    <div className="border animated fadeInDown page-heading flex p-2 mt-0 bg-white">
                        <div className="page-name">
                            <UsergroupAddOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">Mesajlar</span>
                        </div>
                    </div>
                </Col>
                <Col xs={24}>
                    <div className="w-100 mb-10 p-2 bg-white">
                        <Select
                            className={'w-100'}
                            showSearch
                            onChange={(s)=>{setCategory(s)}}
                            notFoundContent={null}
                            placeholder={'Filter'}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                        >
                            <Option  value={undefined}>
                                Hamısı
                            </Option>
                            <Option  value={'1'}>
                               Müraciət
                            </Option>
                            <Option  value={'2'}>
                                Sual
                            </Option>
                            <Option  value={'3'}>
                                Şikayət
                            </Option>
                        </Select>
                    </div>

                    {spin ? (
                        <div className="flex animated fadeIn p-2 bg-white all-center">
                            <Spin size={"large"} />
                        </div>
                    ) : (
                        <Table
                            size="small"
                            className="bg-white animated fadeInUp"
                            columns={initialColumns}
                            dataSource={convertColumns(messages, cols)}
                            pagination={{
                                pageSize: 10,
                                current_page: 1,
                            }}
                        />
                    )}
                </Col>
            </Row>
            <Modal
                title={t("detailedInfo")}
                centered
                className={"padModal"}
                visible={visibleView}
                onOk={() => setVisibleView(false)}
                onCancel={() => setVisibleView(false)}
                footer={[]}
            >
                <View setVisibleView={setVisibleView} message={messages[viewIndex]} />
            </Modal>
        </div>
    );
}

export default connect(null, { notify })(Messages);