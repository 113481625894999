import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {
    Row,
    Col,
    Table,
    Button,
    Tooltip,
    Spin,
    Popconfirm, Select,
} from "antd";
import {
    PicCenterOutlined,
    DeleteFilled,
    EditFilled, UnorderedListOutlined,
} from "@ant-design/icons";
import admin from "../../../../const/api";
import { notify } from "../../../../redux/actions";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../utils/columnconverter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {apiRoutes} from "../../../../const/apiroutes";

const {Option} = Select

function RegionStatistics(props) {
    let mainUrl =  apiRoutes.projects.statistics;
    let regionsUrl =  apiRoutes.projects.regions;
    const [postlist, setPostList] = useState([]);
    const [regions, setRegions] = useState([]);
    const [spin, setSpin] = useState(false);
    const [region_id , setRegionId] = useState()
    const { t } = useTranslation();
    let [trigger, setTrigger] = useState(0);
    let regId = props.match.params.regionId
    const cols = [
        { key: "tableIndex", value: "#", con: true },
        { key: "name", value: t("name"), con: true },
        { key: "image", value: "Logo", con: false },
        { key: "is_active", value: "Status", con: false },
        { key: "limit_exists", value: "Limit", con: false },
        { key: "id", value: "", con: false },
    ];

    const initialColumns = [
        {
            title: "Şəkil",
            dataIndex: "image",
            key: "9",
            render: (i) => {
                return <img className={"tableImage"} src={i.path} alt="" />;
            },
        },
        {
            title:'Region' ,
            dataIndex: "region_id",
            key: "4",
            render: i => {
                return (
                    <div>
                        {regions.find(s=> s.id === i)?.name}
                    </div>
                )
            }
        },
        {
            title: "",
            dataIndex: "id",
            key: "9",
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Link
                                to={{
                                    pathname: `/projects/regions/${regId}/edit/${i}`,
                                    state: {
                                        locales: postlist.find((p) => {
                                            return p.id == i;
                                        })?.locales,
                                    },
                                }}
                            >
                                <Button className="border-none" type="text" shape="circle">
                                    <EditFilled />
                                </Button>
                            </Link>
                        </Tooltip>
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deletePost(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];

    const { notify } = props;

    const deletePost = async (i) => {
        if (i === 0 || i) {
            await admin
                .delete(`${mainUrl}/${i}`)
                .then((res) => {
                    setTrigger(++trigger);
                    notify("silindi", true);
                })
                .catch((res) => {
                    notify(res.err, false);
                });
        }
    };

    const getPostList = (region_id , type) => {
        setSpin(true);
        admin.get(mainUrl ,
            {
                params:{
                    region_id  ,
                    type
                }
            }
            ).then((res, ) => {
            res.data.content && setSpin(false);
            console.log(res.data.content)
            setPostList(
                res.data.content.map((d, index) => {
                    return {
                        ...d,
                        key: index + 1,
                        index,
                        tableIndex: index + 1,
                    };
                })
            );
        })
        .catch(()=>{
            notify(false , '')
            setPostList([])
        });
    };


    const getRegions = () => {
        setSpin(true);
        admin.get(regionsUrl).then((res) => {
            res.data.content && setSpin(false);
            console.log(res.data.content.data)
            setRegions(
                res.data.content.data.map((d, index) => {
                    return {
                        ...d,
                        key: index + 1,
                        index,
                        tableIndex: index + 1,
                        name: d.locales.find((l) => {
                            return (
                                l.local === localStorage.getItem("locale")
                            );
                        }).name,
                    };
                })
            );
        })
        .catch(()=>{
            notify(false , '')
            setPostList([])
        });
    };



    useEffect(() => {
        getRegions();
    }, [t, trigger]);


    useEffect(()=>{
        getPostList(regId);
    } , [region_id , trigger  , t])

    return (
        <div>
            <>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <div className="border animated fadeInDown p-2 mt-0 bg-white">
                            <div className="flex flex-between">
                                <div>
                                    <UnorderedListOutlined className="f-20 mr5-15" />
                                    <span className="f-20 bold">{regions.find((s)=>s.id === parseInt(regId))?.name}</span>
                                </div>

                                <div>
                                    <Link
                                        className={'animated fadeIn'}
                                        to={{
                                            pathname: `/projects/regions/${regId}/create`,
                                            state: { locales: null },
                                        }}
                                    >
                                        <Button type={"primary"}>Əlavə et</Button>
                                    </Link>
                                </div>

                            </div>

                        </div>
                    </Col>

                    {/*<Col md={24} xs={24}>*/}
                    {/*    <Select*/}
                    {/*        className={'w-100'}*/}
                    {/*        showSearch*/}
                    {/*        onChange={(s)=>{setRegionId(s)}}*/}
                    {/*        placeholder={'Region'}*/}
                    {/*        notFoundContent={null}*/}
                    {/*        optionFilterProp="children"*/}
                    {/*        filterOption={(input, option) =>*/}
                    {/*            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0*/}
                    {/*        }*/}
                    {/*        filterSort={(optionA, optionB) =>*/}
                    {/*            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())*/}
                    {/*        }*/}
                    {/*    >*/}
                    {/*        <Option  value={undefined}>*/}
                    {/*            Hamısı*/}
                    {/*        </Option>*/}
                    {/*        {*/}
                    {/*            regions.map((s, i)=>(*/}
                    {/*                <Option  key={i} value={s.id}>*/}
                    {/*                    {s.name}*/}
                    {/*                </Option>*/}
                    {/*            ))*/}
                    {/*        }*/}
                    {/*    </Select>*/}
                    {/*</Col>*/}
                    <Col xs={24}>
                        {spin ? (
                            <div className="flex animated fadeInUp bg-white all-center p-2">
                                <Spin size={"large"} />
                            </div>
                        ) : (
                            <Table
                                size="small"
                                className="bg-white animated fadeIn"
                                columns={initialColumns}
                                dataSource={convertColumns(postlist, cols)}
                                pagination={{
                                    pageSize: 25,
                                    current_page: 1,
                                }}
                            />
                        )}

                    </Col>
                </Row>

            </>
        </div>
    );
}


export default connect(null, { notify })(RegionStatistics);
