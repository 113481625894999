import React from "react";
import {Row, Col, Button} from "antd";
import { useTranslation } from "react-i18next";
// import moment from "moment";

const View = (props) => {
    const w = props.message;
    const { t } = useTranslation();

    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col lg={24} xs={24}>
                    <table className="customtable">
                        <tbody>
                        <tr>
                            <td>Mesaj</td>
                        </tr>
                        <tr>
                            <td>{w.text}</td>
                        </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>
            <div
                className="modalButtons"
                style={{ position: "absolute", bottom: "20px", right: "20px" }}
            >
                <Button className={'mr-15'} onClick={() => props.setVisibleView(false)}>
                    {t("close")}
                </Button>
                <Button type={'primary'}>
                    <a target='_blank' href={`mailto:${w.email}`}>Mail ilə cavabla</a>
                </Button>
            </div>
        </div>
    );
};

export default View;