import React, {Component} from 'react';
import {connect} from "react-redux";
import {notify} from "../../../../redux/actions";
import {whiteSpace , noWhitespace} from "../../../../utils/rules";
import {withTranslation} from "react-i18next";
import {
    Spin,
    Col,
    Input,
    Modal,
    Form,
    Row,
    DatePicker,
    Button,
    Switch, TimePicker, Select,
} from "antd";
import { PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import admin from "../../../../const/api";
import JoditEditor from "jodit-react";
import moment from "moment";
import {apiRoutes} from "../../../../const/apiroutes";
const {Option} = Select

class EditNationalSt extends Component {
    formRef = React.createRef();
    editor = React.createRef();
    mainUrl = apiRoutes.posts.nationalStandarts
    categoryUrl = apiRoutes.admin.nationalStandartsCategories
    id =  this.props.match.params.id

    constructor(props) {
        super(props);
    }

    state = {
        aboutt: {},
        categories:[],
        isActive:false,
        file: null,
        fileList: [],
        previewImage: "",
        previewVisible: false,
    };

    onChange = ({ fileList: newFileList }) => {
        this.setState({fileList:newFileList})
        if (newFileList.length <= 0) {
            this.setState({file:null})
        }
    };

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }






    componentDidMount() {
        if (this.id) {
            this.formRef.current.resetFields()
            let obj = {};
            let datas = {}
            const getPost = async () => {
                await admin.get(`${this.mainUrl}/${this.id}`).then((res) => {
                    console.log(res.data.content)
                    res.data.content.locales.forEach((d) => {
                        obj[`title_${d.local}`] = d.title;
                        obj[`body_${d.local}`] = d.text;
                        datas[`body_${d.local}`] = d.text;
                    });
                    obj['national_standart_category_id'] = res.data.content.categories.id
                    this.setState({
                        aboutt:datas,
                    });
                    if(this.formRef.current !== null){
                        this.formRef.current.setFieldsValue(obj);
                    }
                });
            };
            getPost();
        }
        const getCategories = async () => {
            await admin.get(this.categoryUrl , {params:{per_page:4000}}).then((res) => {
                console.log(res.data.content.data)
                this.setState({
                    categories:
                        res.data.content.data.map((s)=>{
                            return {
                                ...s,
                                name:s.locales.find((l)=>l.local === 'az').name
                            }
                        })
                });
            });
        };
        getCategories();
    }



    saveItem = async (values) => {
        let obj = {
            national_standart_category_id: values.national_standart_category_id,
            locales: this.props.langs.map((l) => {
                return {
                    local: l.key,
                    text: values[`body_${l.key}`],
                };
            }),
        };

        if (this.id === 'create') {
            await admin
                .post(this.mainUrl, obj)
                .then((res) => {
                    this.props.notify("", true);
                    this.formRef.current.resetFields();
                    window.history.back();
                })
                .catch((err) => {
                    this.errorGenerator(err.response.data.error)
                });
        } else {
            obj["id"] = this.props.match.params.id;
            await admin
                .put(`${this.mainUrl}/${this.props.match.params.id}`, obj)
                .then((res) => {
                    this.props.notify("", true);
                    this.formRef.current.resetFields();
                    window.history.back();
                })
                .catch((err) => {
                    this.errorGenerator(err.response.data.error)
                });
        }
    };


    errorGenerator(error){
        notify('Xəta baş verdi', false);
    }

    render() {
        let { langs , t,  getLangs, notify } = this.props;

        return (
            <div>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                            <div className="page-name">
                                <PicCenterOutlined className="f-20 mr5-15" />
                                <span className="f-20 bold">Standart {this.id ? 'i redaktə et' : '  əlavə et'} </span>
                            </div>
                            <Link
                                to={{
                                    pathname: `/national/standart`,
                                    state: { locales: null, name: this.props.location.state.name },
                                }}
                            >
                                <Button type={"primary"}>{t("cancel")}</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={24}>
                        <div className={`p-2 animated edit fadeInUp bg-white`}>
                            <Form ref={this.formRef} onFinish={this.saveItem}  layout="vertical">
                                <Row gutter={[8, 8]}>
                                    <Col md={12} sm={12} xs={24}>
                                        <Form.Item
                                            label={'Kateqoriya'}
                                            name={"national_standart_category_id"}
                                            validateTrigger="onChange"
                                            rules={[noWhitespace(t("inputError"))]}
                                        >
                                            <Select
                                                showSearch
                                                className={'w-100'}
                                                notFoundContent={null}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            >
                                                {
                                                    this.state.categories.map((w, i) => {
                                                        return (
                                                            <Option key={i} value={w.id}>
                                                                {w.name}
                                                            </Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>

                                    </Col>



                                    <Col xs={24}>
                                        {this.props.langs.map((l) => (
                                            <div key={l.id} className="form-lang">
                                                <div className="mb-10">
                                                    {l.name}
                                                </div>
                                                <Form.Item
                                                    name={`body_${l.key}`}
                                                    validateTrigger="onChange"
                                                    // getValueFromEvent={(event, editor) => {
                                                    //     const dat = editor.getData();
                                                    //     return dat;
                                                    // }}
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <JoditEditor
                                                        ref={this.editor}
                                                        value={this.state.aboutt[`about_${l.key}`]}
                                                        tabIndex={1} // tabIndex of textarea
                                                    />
                                                </Form.Item>
                                            </div>
                                        ))}
                                    </Col>
                                    <Col xs={24}>
                                        <div className={"flex"}>
                                            <Button className={"mr-15"} htmlType="submit">
                                                {t("save")}
                                            </Button>
                                            <Link
                                                to={{
                                                    pathname: `/national/standart`,
                                                    state: {
                                                        locales: null,
                                                        name: this.props.location.state.name,
                                                    },
                                                }}
                                            >
                                                <Button type={"primary"}>{t("cancel")}</Button>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>

                <Modal
                    visible={this.state.previewVisible}
                    title={false}
                    footer={null}
                    onCancel={() => {
                        this.setState({previewVisible:false})
                    }}
                >
                    <img alt="example" style={{ width: "100%" }} src={this.state.previewImage}  />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

const exp = withTranslation()(EditNationalSt);
export default connect(mapStateToProps, { notify })(exp);
