import React, { useState, useEffect } from "react";
import { Col, Row, Button, Card, Form, Spin } from "antd";
import {
    PicCenterOutlined,
    MailOutlined,
    PrinterOutlined,RightSquareOutlined,
    PhoneOutlined, FacebookOutlined, InstagramOutlined, LinkedinOutlined, YoutubeFilled, TwitterOutlined, YoutubeOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import GoogleM from "../../../Elements/GoogleM";
import admin from "../../../../const/api";
import { useTranslation } from "react-i18next";
import history from '../../../../const/history'
import {apiRoutes} from "../../../../const/apiroutes";

function ContactInfo(props) {
    let mainUrl = apiRoutes.contact.contactInfo
    const [spin, setSpin] = useState(false);
    const [showmap, setShowMap] = useState(false);
    const [infos, setInfos] = useState({});
    const { t } = useTranslation();
    const [map, setMap] = useState([]);
    const getInfos = () => {
        setSpin(true);
        admin.get(mainUrl , {params: {per_page:20000}}).then((res) => {
            res.data.content[0] && setSpin(false);
            let data = res.data.content[0]
            if (res.data.content[0]){
                setInfos(data)
                setMap([res.data.content[0].lat , res.data.content[0].lng]);
                setShowMap(true);
            }
            else{
                history.push('contact-info/edit')
            }
        });
    };
    useEffect(() => {
        getInfos();
    }, [t]);
    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <div className="border  flex-between pag  e-heading flex p-2 mt-0 bg-white">
                        <div className="page-name">
                            <PicCenterOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">Əlaqə məlumatları</span>
                        </div>
                        <div>
                            <Link to={"/contact-info/edit"}>
                                {" "}
                                <Button type={"primary"}>{t("edit")}</Button>
                            </Link>
                        </div>
                    </div>
                </Col>
                <Col md={8}>
                    <Card loading={spin} className={"animated fadeInLeft"}>
                        <div className="mb-20">
                            <p className={"f-18"}>
                                <PhoneOutlined /> Telefon
                            </p>
                            <p  className={"f-17"}>
                                {infos.tel}
                            </p>
                        </div>
                        <div className="mb-20">
                            <p className={"f-18"}>
                                <PrinterOutlined /> Faks
                            </p>
                            <p  className={"f-17"}>
                                {infos.fax}
                            </p>
                        </div>


                        <div className="mb-20">
                            <p className={"f-18"}>
                                <MailOutlined /> Email
                            </p>
                            <p  className={"f-17"}>
                                {infos.email}
                            </p>
                        </div>
                    </Card>
                </Col>
                <Col md={16}>
                    <Card loading={spin} className={"d-block h-100 animated fadeInRight"}>
                        <div>
                            <p className={"f-20"}>{t("Address")}</p>
                            <p className={"f-17"}>{infos?.address}</p>
                        </div>
                        <div className={"w-100  h-100 d-block position-relative"}>
                            {showmap ? (
                                <div className="maps">
                                    <GoogleM address={infos?.address} map={map} />
                                </div>
                            ) : (
                                <Card className="flex all-center">
                                    <Spin size={"large"}> </Spin>
                                </Card>
                            )}
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ContactInfo;