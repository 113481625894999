import React, {Component} from 'react';
import {connect} from "react-redux";
import {notify} from "../../../../redux/actions";
import {whiteSpace , noWhitespace} from "../../../../utils/rules";
import {withTranslation} from "react-i18next";
import {
    Spin,
    Col,
    Input,
    Modal,
    Form,
    Row,
    DatePicker,
    Button,
    Switch, TimePicker, Select,
} from "antd";
import { PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import admin from "../../../../const/api";
import JoditEditor from "jodit-react";
import moment from "moment";
import {apiRoutes} from "../../../../const/apiroutes";
const {Option} = Select

class EditLeaderShip extends Component {
    formRef = React.createRef();
    editor = React.createRef();
    mainUrl = apiRoutes.agency.leaderShip
    id =  this.props.match.params.id

    constructor(props) {
        super(props);
    }

    state = {
        about: {},
        categories:[
             {
                id:1,
                name:'Əsas',
             },
            {
                id:2,
                name:'Köməkçi',
            },
        ],
        isActive:false,
        file: null,
        fileList: [],
        previewImage: "",
        previewVisible: false,
    };

    onChange = ({ fileList: newFileList }) => {
        this.setState({fileList:newFileList})
        if (newFileList.length <= 0) {
            this.setState({file:null})
        }
    };

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }
        this.setState({
            previewImage:file.url || file.preview,
            previewVisible:true
        })
    };

    setUploadFile = ({ onSuccess, onError, file }) => {
        let form_data = new FormData();
        const filename = Math.random(1, 999999) + Date.now() + file.name;
        form_data.append("image_uuid", file, filename);
        admin
            .post(apiRoutes.upload.image, form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                this.setState({file: res.data.content.image__uuid})
                onSuccess(null, file);
            })
            .catch((err) => {
                this.props.notify(err.response.data.error.image_uuid[0], true);
            });
    };


    componentDidMount() {
        if (this.id) {
            this.formRef.current.resetFields()
            let obj = {};
            let abouts = {}
            const getPost = async () => {
                await admin.get(`${this.mainUrl}/${this.id}`).then((res) => {
                    let arr = [...this.state.fileList];
                    console.log(res)
                    this.setState({file:res.data.content.image_uuid});
                    arr.push({
                        uid: res.data.content.image_uuid,
                        url: res.data.content.image.path,
                    });
                    this.setState({
                        fileList:arr,
                    });
                    res.data.content.locales.forEach((d) => {
                        obj[`full_name_${d.local}`] = d.full_name;
                        obj[`position_${d.local}`] = d.position;
                        obj[`text_${d.local}`] = d.text;
                        abouts[`text_${d.local}`] = d.text;
                    });
                    obj['status'] = parseInt(res.data.content.status)

                    if(this.formRef.current !== null){
                        this.formRef.current.setFieldsValue(obj);
                    }
                });
            };
            getPost();
        }
    }



    saveItem = async (values) => {
        let obj = {
            order:Math.random(),
            status: values.status,
            image_uuid: this.state.file,
            locales: this.props.langs.map((l) => {
                return {
                    local: l.key,
                    full_name: values[`full_name_${l.key}`],
                    position: values[`position_${l.key}`],
                    text: values[`text_${l.key}`],
                };
            }),
        };
        if (!this.id) {
            await admin
                .post(this.mainUrl, obj)
                .then((res) => {
                    this.props.notify("", true);
                    this.formRef.current.resetFields();
                    window.history.back();
                })
                .catch((err) => {
                    this.errorGenerator(err.response.data.error)
                });
        } else {
            obj["id"] = this.props.match.params.id;
            await admin
                .put(`${this.mainUrl}/${this.props.match.params.id}`, obj)
                .then((res) => {
                    this.props.notify("", true);
                    this.formRef.current.resetFields();
                    window.history.back();
                })
                .catch((err) => {
                    this.errorGenerator(err.response.data.error)
                });
        }
    };


    errorGenerator(error){
        notify('Xəta baş verdi', false);
    }

    render() {
        let { langs , t,  getLangs, notify } = this.props;

        return (
            <div>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                            <div className="page-name">
                                <PicCenterOutlined className="f-20 mr5-15" />
                                <span className="f-20 bold">Rəhbər{this.id ? 'i redaktə et' : '  əlavə et'} </span>
                            </div>
                            <Link
                                to={{
                                    pathname: `/leadership`,
                                    state: { locales: null, name: this.props.location.state.name },
                                }}
                            >
                                <Button type={"primary"}>{t("cancel")}</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={24}>
                        {/*<div className={`${(this.props.match.params.id && !this.state.fileList.length >= 1) ? 'flex animated fadeIn p-2 bg-white all-center' : 'd-none'}`}>*/}
                        {/*    <Spin size={"large"} />*/}
                        {/*</div>*/}
                        <div className={`p-2 animated edit fadeInUp bg-white`}>
                            <Form ref={this.formRef} onFinish={this.saveItem}  layout="vertical">
                                <Row gutter={[8, 8]}>
                                    <Col xs={12}>
                                        <Form.Item
                                            validateTrigger="onChange"
                                            name={`photo`}
                                            required
                                        >
                                            {/*<ImgCrop*/}
                                            {/*    className={"w-100"}*/}
                                            {/*    rotate*/}
                                            {/*    aspect={1024 / 576}*/}
                                            {/*    grid*/}
                                            {/*>*/}
                                                <Upload
                                                    accept=".png, .jpg , .jpeg"
                                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                    listType="picture-card"
                                                    fileList={this.state.fileList}
                                                    onChange={this.onChange}
                                                    onPreview={this.handlePreview}
                                                    customRequest={this.setUploadFile}
                                                    beforeUpload={null}
                                                >
                                                    {this.state.fileList.length < 1 && "+ Yüklə"}
                                                </Upload>
                                            {/*</ImgCrop>*/}
                                        </Form.Item>
                                    </Col>

                                    <Col md={12} sm={12} xs={24}>
                                        <p className={"mb-15"}>Ad</p>
                                        {this.props.langs.map((l) => (
                                            <div key={l.id} className="form-lang">
                                                <Form.Item
                                                    validateTrigger="onChange"
                                                    name={`full_name_${l.key}`}
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <Input className="w-100" />
                                                </Form.Item>
                                                <span className="input-lang ">{l.key}</span>
                                            </div>
                                        ))}

                                        <Form.Item
                                            label={'Kateqoriya'}
                                            name={"status"}
                                            validateTrigger="onChange"
                                            rules={[noWhitespace(t("inputError"))]}
                                        >
                                            <Select
                                                showSearch
                                                className={'w-100'}
                                                notFoundContent={null}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            >
                                                {
                                                    this.state.categories.map((w, i) => {
                                                        return (
                                                            <Option key={i} value={w.id}>
                                                                {w.name}
                                                            </Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>

                                    </Col>





                                    <Col xs={12}>
                                        <div className="mb-10">Pozisiya</div>
                                        {this.props.langs.map((l) => (
                                            <div key={l.id} className="form-lang">
                                                <Form.Item
                                                    validateTrigger="onChange"
                                                    name={`position_${l.key}`}
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <Input className="w-100" />
                                                </Form.Item>
                                                <span className="input-lang ">{l.key}</span>
                                            </div>
                                        ))}
                                    </Col>


                                    <Col md={24} sm={24} xs={24}>
                                        {this.props.langs.map((l) => (
                                            <div key={l.id} className="form-lang">
                                                <div className="mb-10">
                                                    {l.name}
                                                </div>
                                                <Form.Item
                                                    name={`text_${l.key}`}
                                                    validateTrigger="onChange"
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <JoditEditor
                                                        ref={this.editor}
                                                        value={this.state.about[`text_${l.key}`]}
                                                        tabIndex={1} // tabIndex of textarea
                                                    />
                                                </Form.Item>

                                            </div>
                                        ))}
                                    </Col>

                                    <Col xs={24}>
                                        <div className={"flex"}>
                                            <Button className={"mr-15"} htmlType="submit">
                                                {t("save")}
                                            </Button>
                                            <Link
                                                to={{
                                                    pathname: `/leadership`,
                                                    state: {
                                                        locales: null,
                                                        name: this.props.location.state.name,
                                                    },
                                                }}
                                            >
                                                <Button type={"primary"}>{t("cancel")}</Button>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>

                <Modal
                    visible={this.state.previewVisible}
                    title={false}
                    footer={null}
                    onCancel={() => {
                        this.setState({previewVisible:false})
                    }}
                >
                    <img alt="example" style={{ width: "100%" }} src={this.state.previewImage}  />
                </Modal>
            </div>
        );
    }
}



const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

const exp = withTranslation()(EditLeaderShip);
export default connect(mapStateToProps, { notify })(exp);
