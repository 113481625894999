import React, { useEffect, useState, useRef } from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Switch,
    Spin,
    Form,
    Tooltip,
    Input,
    Popconfirm, Upload, Select,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled, EyeOutlined, EyeFilled,
} from "@ant-design/icons";
import { convertColumns } from "../../../../utils/columnconverter";
import { notify } from "../../../../redux/actions";
import { connect } from "react-redux";
import admin from "../../../../const/api";
import { useTranslation } from "react-i18next";
import {noWhitespace, whiteSpace} from "../../../../utils/rules";
import {apiRoutes} from "../../../../const/apiroutes";
import ImgCrop from "antd-img-crop";
import history from "../../../../const/history";
import {Link} from "react-router-dom";
const {Option} = Select
const ProjectCategory = (props) => {
    const mainUrl = apiRoutes.admin.projectCategory
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [fileList , setFileList] = useState([])
    const [previewVisible , setPreviewVisible] = useState(false)
    const [previewImage , setPreviewImage] = useState([])
    const [file , setFile] = useState([])
    const [isActive, setActive] = useState(false);
    const [positions, setPositions] = useState([]);
    const [spin, setSpin] = useState(false);
    const [editing, setEditing] = useState(null);
    const cols = [
        { key: "index", value: "#", con: true },
        { key: "name", value: t("name"), con: true },
        { key: "id", value: "", con: false },
    ];
    const nameInput = useRef();

    // props
    const { notify } = props;

    const columns = [
        {
            title: "",
            dataIndex: "image",
            key: "9",
            render: (i) => {
                return <img className={"tableImage"} src={i.path} alt="" />;
            },
        },
        {
            title: t("name"),
            key: "2",
            dataIndex: "name",
        },
        {
            title: "",
            key: "3",
            dataIndex: "s",
            width: 30,
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Tooltip className="ml-5" title={'Layihələrə bax'}>
                            <Link
                                to={{
                                    pathname: `/projects/finished/${i.id}`,
                                    state: {name: i.name },
                                }}
                            >
                                <Button type={"primary"}><EyeOutlined className={'ml-5 "mr5-5'}/> Layihələr</Button>
                            </Link>
                        </Tooltip>
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deletePosition(i.id)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Button
                                className="border-none"
                                type="text"
                                shape="circle"
                                onClick={() => setEditingObject(i.id)}
                            >
                                <EditFilled />
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];


    const onSwitchChange = () => {
        setActive(!isActive);
    };

    const setEditingObject = async (i) => {
        setEditing(i);
        await admin.get(mainUrl , {params: {per_page:20000}}).then((res) => {
            let names = res.data.content.find((c) => {
                return c.id === i;
            });
            setFile(names.image_uuid)
            setFileList([
                {
                    uid: names.image_uuid,
                    url: names.image.path,
                }
            ])
            let obj = {};
            names.locales.forEach((name) => {
                obj[`name_${name.local}`] = name.title;
                obj[`city_${name.local}`] = name.city;
                obj[`status`] = name.status;
            });
            setActive(names.is_active === 1)
            form.setFieldsValue(obj);
        });
    };

    const cancelEditing = () => {
        setEditing(null);
        setFile(null)
        setFileList([])
        setActive(false)
        form.resetFields();
    };


    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList)
        if (newFileList.length <= 0) {
            setFile(null)
        }
    };


    const  getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    const  handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview)
        setPreviewVisible(true)
    };

    const  setUploadFile = ({ onSuccess, onError, file }) => {
        let form_data = new FormData();
        const filename = Math.random(1, 999999) + Date.now() + file.name;
        form_data.append("image_uuid", file, filename);
        admin
            .post(apiRoutes.upload.image, form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                setFile(res.data.content.image__uuid)
                onSuccess(null, file);
            })
            .catch((err) => onError());
    };


    const deletePosition = async (i) => {
        await admin
            .delete(`${mainUrl}/${i}`)
            .then(() => {
                // description
                notify("silindi", true);
                getPositions();
            })
            .catch((err) => {
                //error
                notify(err.response, false);
            });
    };

    const savePosition = async (values) => {
        let obj = {
            image_uuid:file,
            locales: props.langs.map((l, index) => {
                return {
                    local: l.key,
                    status:values.status,
                    city: values[`city_${l.key}`] ,
                    title: values[`name_${l.key}`] ,
                };
            }),
        };
        if (!editing) {
            await admin
                .post(mainUrl, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            obj["id"] = editing;
            await admin
                .put(`${mainUrl}/${editing}`, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };

    const getPositions = async () => {
        setSpin(true);
        await admin.get(mainUrl , {params: {per_page:20000}}).then((res) => {
            setSpin(false);
            setPositions(
                res.data.content.map((p, index) => {
                    return {
                        key: index + 1,
                        ...p,
                        index: index + 1,
                        name: p.locales.find((l) => {
                            return l.local === localStorage.getItem("locale");
                        }).title,
                        s:{
                            id:p.id,
                            name: p.locales.find((l) => {
                                return l.local === localStorage.getItem("locale");
                            }).title
                        }
                    };
                })
            );
        });
    };

    useEffect(() => {
        getPositions();
    }, [t]);

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border animated fadeInDown p-2 mt-0 bg-white">
                    <UnorderedListOutlined className="f-20 mr5-15" />
                    <span className="f-20 bold">Layihələr</span>
                </div>
            </Col>
            <Col lg={12} xs={24}>
                <Table
                    loading={spin}
                    size="small"
                    className="bg-white animated fadeInLeft"
                    columns={columns}
                    dataSource={convertColumns(positions, cols)}
                    pagination={{
                        pageSize: 10,
                        current_page: 1,
                        total: positions.length,
                    }}
                />
            </Col>
            <Col lg={12} xs={24}>
                <Card title={t("addTo")} className={"animated fadeInRight"}>
                    <Form layout="vertical" onFinish={savePosition} form={form}>
                        <Form.Item
                            className={'animated fadeIn'}
                            validateTrigger="onChange"
                            name={`photo`}
                            required
                        >
                            {/*<ImgCrop*/}
                            {/*    className={"w-100"}*/}
                            {/*    rotate*/}
                            {/*    aspect={16 / 9}*/}
                            {/*    grid*/}
                            {/*>*/}
                                <Upload
                                    accept=".png, .jpg , .jpeg"
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={onChange}
                                    onPreview={handlePreview}
                                    customRequest={setUploadFile}
                                    beforeUpload={null}
                                >
                                    {fileList.length < 1 && "+ Yüklə"}
                                </Upload>
                            {/*</ImgCrop>*/}
                        </Form.Item>


                        <p className="mb-5">Adı</p>
                        {props.langs ? (
                            props.langs.map((l, i) => {
                                return (
                                    <div key={l.id} className="form-lang">
                                        <Form.Item
                                            className="mb-5"
                                            validateTrigger="onChange"
                                            name={`name_${l.key}`}
                                            rules={[whiteSpace(t("inputError"))]}
                                        >
                                            <Input ref={nameInput} />
                                        </Form.Item>
                                        <div className="input-lang">{l.key}</div>
                                    </div>
                                );
                            })
                        ) : (
                            <Spin size={"large"} />
                        )}

                        <p className="mb-5">Rayon</p>
                        {props.langs ? (
                            props.langs.map((l, i) => {
                                return (
                                    <div key={l.id} className="form-lang">
                                        <Form.Item
                                            className="mb-5"
                                            validateTrigger="onChange"
                                            name={`city_${l.key}`}
                                            rules={[whiteSpace(t("inputError"))]}
                                        >
                                            <Input ref={nameInput} />
                                        </Form.Item>
                                        <div className="input-lang">{l.key}</div>
                                    </div>
                                );
                            })
                        ) : (
                            <Spin size={"large"} />
                        )}


                        <div className={'animated fadeInRight'}>
                            <p className="mb-5">Kateqoriya</p>
                            <Form.Item
                                className="mb-5"
                                validateTrigger="onChange"
                                name={`status`}
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <Select
                                    showSearch
                                    notFoundContent={null}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >
                                    <Option  value={'1'}>
                                        Daxili layihələr
                                    </Option>
                                    <Option  value={'2'}>
                                        Beynəxalq layihələr
                                    </Option>
                                </Select>
                            </Form.Item>
                        </div>

                        {/*<div className="flex mt-10 flex-align-center">*/}
                        {/*    <Switch checked={isActive} onChange={onSwitchChange} />*/}
                        {/*    <span className="ml-10 mr-10">Status</span>*/}
                        {/*    <span className={isActive ? "green" : "red"}>*/}
                        {/*   {isActive ? "Aktiv" : "Deaktiv"}*/}
                        {/*  </span>*/}
                        {/*</div>*/}
                        <div className="flex  flex-between mt-15">
                            <Button onClick={cancelEditing}>{t("cancel")}</Button>
                            <Button htmlType="submit">{t("save")}</Button>
                        </div>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, { notify })(ProjectCategory);