import React , {useState} from 'react';
import {UnorderedListOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {
    Row,
    Col,
    Tabs, Button,
} from "antd";
import RegionStatistics from "../Regions/RegionStatistics";
import HomeStatistics from "./HomeStatistics";
import {Link} from "react-router-dom";
const { TabPane } = Tabs;

function Statistics(props) {
    const { t } = useTranslation();
    const [tabKey, setTabKey] = useState("1");

    const handleTabChange = (e) => {
        setTabKey(e);
    };

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border animated fadeInDown p-2 mt-0 bg-white">
                    <div className="flex flex-between">
                        <div>
                            <UnorderedListOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">Statistika</span>
                        </div>

                        {/*{*/}
                        {/*    tabKey === '1' ?*/}
                        {/*        <div>*/}
                        {/*            <Link*/}
                        {/*                className={'animated fadeIn'}*/}
                        {/*                to={{*/}
                        {/*                    pathname: `/statistics/create`,*/}
                        {/*                    state: { locales: null },*/}
                        {/*                }}*/}
                        {/*            >*/}
                        {/*                <Button type={"primary"}>Əlavə et</Button>*/}
                        {/*            </Link>*/}
                        {/*        </div> : ''*/}
                        {/*}*/}


                    </div>

                </div>
            </Col>
            <Col xs={24}>
                <div>
                    <HomeStatistics tabKey={tabKey} />
                </div>
                {/*<div className="tab-section">*/}
                {/*    <Tabs*/}
                {/*        onChange={handleTabChange}*/}
                {/*        defaultActiveKey={tabKey}*/}
                {/*        className="bg-white w-100"*/}
                {/*    >*/}
                {/*        <TabPane tab={'Qarabağ Regionlar'} key="1">*/}
                {/*            <div className="p-2">*/}
                {/*                <RegionStatistics  tabKey={tabKey} />*/}
                {/*            </div>*/}
                {/*        </TabPane>*/}
                {/*        <TabPane tab={'Statistika ümumi'} key="2">*/}
                {/*            <div className="p-2">*/}
                {/*                <HomeStatistics tabKey={tabKey} />*/}
                {/*            </div>*/}
                {/*        </TabPane>*/}
                {/*    </Tabs>*/}
                {/*</div>*/}
            </Col>
        </Row>
    );
}


export default Statistics;

