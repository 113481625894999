import React, { useEffect, useState, useRef } from "react";
import {
    Card,
    Table,
    Button,
    Spin,
    Form,
    Select,
    Row,
    Col,
    Tooltip,
    Input,
    Popconfirm, Collapse, Switch, Upload, Modal,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled,
} from "@ant-design/icons";
import { convertColumns } from "../../../../utils/columnconverter";
import { notify, getLangs } from "../../../../redux/actions";
import { connect } from "react-redux";
import admin from "../../../../const/api";
import { useTranslation } from "react-i18next";
import {noWhitespace, whiteSpace} from "../../../../utils/rules";
import {apiRoutes} from "../../../../const/apiroutes";
import * as PropTypes from "prop-types";
import ImgCrop from "antd-img-crop";

const {Option} = Select;
const { Panel } = Collapse;



const Pages = (props) => {
    const mainUrl = apiRoutes.admin.pages
    const subMainUrl = apiRoutes.admin.subPages
    const [fileList , setFileList] = useState([])
    const [previewVisible , setPreviewVisible] = useState(false)
    const [previewImage , setPreviewImage] = useState([])
    const [file , setFile] = useState([])
    const [isActive, setActive] = useState(false);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [positions, setPositions] = useState([]);
    const [spin, setSpin] = useState(false);
    const [editing, setEditing] = useState(null);
    const [editedObject, setEditedObject] = useState(null);
    const [type, setType] = useState(null);
    const cols = [
        { key: "index", value: "#", con: true },
        { key: "name", value: t("name"), con: true },
        { key: "position", value: t('type'), con: false },
        { key: "id", value: "", con: false },
    ];
    const nameInput = useRef();



    // props
    const { notify } = props;

    const columns = [
        {
            title: "#",
            key: "1",
            dataIndex: "index",
            width: 80,
        },
        {
            title: t("name"),
            key: "2",
            dataIndex: "name",
        },
        {
            title: "Status",
            dataIndex: "is_active",
            key: "6",
            render: (i) => {
                return i === 1 ? (
                    <span className="green">Aktiv</span>
                ) : (
                    <span className="red">Deaktiv</span>
                );
            },
        },
        {
            title: "",
            key: "3",
            dataIndex: "id",
            width: 30,
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        {/*<Popconfirm*/}
                        {/*    placement="topRight"*/}
                        {/*    title={t("areYouSure")}*/}
                        {/*    onConfirm={() => deletePosition(i , 'sub')}*/}
                        {/*    okText={t("yes")}*/}
                        {/*    cancelText={t("no")}*/}
                        {/*>*/}
                        {/*    <Tooltip className="ml-5" title={t("delete")}>*/}
                        {/*        <Button className="border-none" type="text" shape="circle">*/}
                        {/*            <DeleteFilled />*/}
                        {/*        </Button>*/}
                        {/*    </Tooltip>*/}
                        {/*</Popconfirm>*/}
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Button
                                className="border-none"
                                type="text"
                                shape="circle"
                                onClick={() => setEditingObject(i , 'sub')}
                            >
                                <EditFilled />
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];


    const setEditingObject = async (i , type) => {
        setEditing(i);
        setType(type)
        let data = {}
        await admin.get(`/${type === 'main' ? mainUrl : subMainUrl}/${i}` , {params: {per_page:20000}}).then((res) => {
            data = res.data.content
            setEditedObject(data)
            let obj = {};
            console.log(data)
            if (data.image){
                setFile(data.image_uuid)
                setFileList([
                    {
                        uid: data.image_uuid,
                        url: data.image.path,
                    }
                ])
            }
            else{
                setFile('')
                setFileList([])
            }
            data.locales.forEach((name) => {
                obj[`name_${name.local}`] = name.name;
            });
            if (type === 'sub'){
                obj['page_id'] = data.page_id
            }
            setActive(data.is_active)
            form.setFieldsValue(obj);
        });
    };

    const cancelEditing = () => {
        setFile(null)
        setFileList([])
        setEditing(null);
        setEditedObject(null)
        setType(null)
        setActive(false)
        form.resetFields();
    };

    const deletePosition = async (i , type) => {
        await admin
            .delete(`${type === 'main' ? mainUrl : subMainUrl}/${i}`)
            .then(() => {
                notify("silindi", true);
                getPositions();
            })
            .catch((err) => {
                //error
                notify(err.response, false);
            });
    };

    const savePosition = async (values) => {
        if (!editing) {
            notify("Sadəcə redaktə edə bilərsiniz.", false);
        } else {
            let obj = {
                key: editedObject.key,
                image_uuid:file,
                is_active:isActive,
                locales: props.langs.map((l, index) => {
                    return {
                        local: l.key,
                        name: values[`name_${l.key}`] ,
                    };
                }),
            };
            obj["id"] = editing;
            obj["page_id"] = values.page_id;
            await admin
                .put(`/${type === 'main' ? mainUrl : subMainUrl}/${editing}`, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };

    const getPositions = async () => {
        setSpin(true);
        await admin.get(mainUrl , {params: {per_page:20000}}).then((res) => {
            setSpin(false);
            setPositions(
                res.data.content.data.map((p, index) => {
                    return {
                        key: index + 1,
                        ...p,
                        index: index + 1,
                        name:p.locales.find(d => d.local === localStorage.getItem('locale')).name,
                        sub_page: p.sub_pages.map((s, i)=>{
                            return {
                                key: i + 1,
                                ...p,
                                index: i + 1,
                                ...s,
                                name: s.locales.find(d => d.local === localStorage.getItem('locale')).name ? s.locales.find(d => d.local === localStorage.getItem('locale')).name  : '',
                            }
                        })
                    };
                })
            );
        });
    };



    useEffect(() => {
        getPositions();
    }, [t]);


    const onSwitchChange = () => {
        setActive(!isActive);
    };




    const genExtra = (i) => (
       <div className="flex flex-end flex-align-center">
           <div>
               {i.is_active === 1 ? (
                   <span className="green">Aktiv</span>
                   ) : (
                   <span className="red">Deaktiv</span>
                   )
               }
           </div>
           {/*<Popconfirm*/}
           {/*    placement="topRight"*/}
           {/*    title={t("areYouSure")}*/}
           {/*    onConfirm={() => deletePosition(i.id , 'main')}*/}
           {/*    okText={t("yes")}*/}
           {/*    cancelText={t("no")}*/}
           {/*>*/}
           {/*    <Tooltip className="ml-5" title={t("delete")}>*/}
           {/*        <Button className="border-none" type="text" shape="circle">*/}
           {/*            <DeleteFilled />*/}
           {/*        </Button>*/}
           {/*    </Tooltip>*/}
           {/*</Popconfirm>*/}
           <Tooltip className="ml-5" title={t("edit")} placement="topRight">
               <Button
                   className="border-none"
                   type="text"
                   shape="circle"
                   onClick={() => setEditingObject(i.id , 'main')}
               >
                   <EditFilled />
               </Button>
           </Tooltip>
       </div>
    );

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList)
        if (newFileList.length <= 0) {
            this.setState({file:null})
        }
    };


    const  getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    const  handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview)
        setPreviewVisible(true)
    };

    const  setUploadFile = ({ onSuccess, onError, file }) => {
        let form_data = new FormData();
        const filename = Math.random(1, 999999) + Date.now() + file.name;
        form_data.append("image_uuid", file, filename);
        admin
            .post(apiRoutes.upload.image, form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                setFile(res.data.content.image__uuid)
                onSuccess(null, file);
            })
            .catch((err) => onError());
    };

    return (
      <div>

          <Row gutter={[10, 10]}>
              <Col xs={24}>
                  <div className="border animated fadeInDown p-2 mt-0 bg-white">
                      <UnorderedListOutlined className="f-20 mr5-15" />
                      <span className="f-20 bold">Menyu & Altmenyular</span>
                  </div>
              </Col>
              <Col lg={12} xs={24}>
                  <div className="bg-white p-2">
                      <Spin spinning={spin}>
                          <Collapse
                              expandIconPosition={'left'}
                              collapsible="header">
                              {
                                  positions.map((s, i)=>(
                                      <Panel className={'bg-white'} header={s.name} key={s.id} extra={genExtra(s)}>
                                          <Table
                                              loading={spin}
                                              size="small"
                                              className="bg-white animated fadeInLeft"
                                              columns={columns}
                                              dataSource={convertColumns(s.sub_page, cols)}
                                              pagination={false}
                                          />
                                      </Panel>
                                  ))
                              }
                          </Collapse>
                      </Spin>
                  </div>
              </Col>
              <Col lg={12} xs={24}>
                  <Card title={t("addTo")} className={"animated overflow-hidden fadeInRight"}>
                      <Form layout="vertical" onFinish={savePosition} form={form}>
                          {type !== 'sub' &&
                              <Form.Item
                                  className={'animated fadeIn'}
                                  validateTrigger="onChange"
                                  name={`photo`}
                                  required
                              >
                                  {/*<ImgCrop*/}
                                  {/*    className={"w-100"}*/}
                                  {/*    rotate*/}
                                  {/*    aspect={2000 / 2000}*/}
                                  {/*    grid*/}
                                  {/*>*/}
                                      <Upload
                                          accept=".png, .jpg , .jpeg"
                                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                          listType="picture-card"
                                          fileList={fileList}
                                          onChange={onChange}
                                          onPreview={handlePreview}
                                          customRequest={setUploadFile}
                                          beforeUpload={null}
                                      >
                                          {fileList.length < 1 && "+ Yüklə"}
                                      </Upload>
                                  {/*</ImgCrop>*/}
                              </Form.Item>
                          }

                          <p className="mb-5">Adı</p>
                          {props.langs ? (
                              <div>
                                  {props.langs.map((l, i) => {
                                      return (
                                          <div key={l.id} className="form-lang">
                                              <Form.Item
                                                  className="mb-5"
                                                  validateTrigger="onChange"
                                                  name={`name_${l.key}`}
                                                  rules={[whiteSpace(t("inputError"))]}
                                              >
                                                  <Input ref={nameInput} />
                                              </Form.Item>
                                              <div className="input-lang">{l.key}</div>
                                          </div>
                                      );
                                  })}

                                  {
                                      type === 'sub' ?
                                          <div className={'animated fadeInRight'}>
                                              <p className="mb-5">Əsas səhifə</p>
                                              <Form.Item
                                                  className="mb-5"
                                                  validateTrigger="onChange"
                                                  name={`page_id`}
                                                  rules={[noWhitespace(t("inputError"))]}
                                              >
                                                  <Select
                                                      showSearch
                                                      notFoundContent={null}
                                                      optionFilterProp="children"
                                                      filterOption={(input, option) =>
                                                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                      }
                                                      filterSort={(optionA, optionB) =>
                                                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                      }
                                                  >
                                                      {
                                                          positions.map((s, i)=>(
                                                              <Option  key={i} value={s.id}>
                                                                  {s.name}
                                                              </Option>
                                                          ))
                                                      }
                                                  </Select>
                                              </Form.Item>
                                          </div> : ''
                                  }

                              </div>
                          ) : (
                              <Spin size={"large"} />
                          )}
                          <div className="flex mt-10 flex-align-center">
                              <Switch checked={isActive} onChange={onSwitchChange} />
                              <span className="ml-10 mr-10">Status</span>
                              <span className={isActive ? "green" : "red"}>
                               {isActive ? "Aktiv" : "Deaktiv"}
                            </span>
                          </div>
                          <div className="flex  flex-between mt-15">
                              <Button onClick={cancelEditing}>{t("cancel")}</Button>
                              <Button htmlType="submit">{t("save")}</Button>
                          </div>
                      </Form>
                  </Card>
              </Col>
          </Row>
          <Modal
              visible={previewVisible}
              title={false}
              footer={null}
              onCancel={() => {
                  setPreviewVisible(false)
              }}
          >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
      </div>
    );
};

const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, { notify })(Pages);


