import React, {Component} from 'react';
import {connect} from "react-redux";
import {getLangs, notify} from "../../../../redux/actions";
import {withTranslation} from "react-i18next";
import {
    Spin,
    Col,
    Input,
    Modal,
    Form,
    Row,
    Button,
} from "antd";
import {noWhitespace} from "../../../../utils/rules";
import { PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import JoditEditor from "jodit-react";

import admin from "../../../../const/api";

class EditPublicCouncil extends Component {
    formRef = React.createRef();
    editor = React.createRef();

    constructor(props) {
        super(props);
    }

    state = {
        spin: false,
        about: {},
    };


    getPost = async () => {
        let obj = {};
        let abouts = {}
        await admin.get(`public-council`).then((res) => {
            if (res.data.content?.locales){
                res.data.content.locales.forEach((d) => {
                    obj[`about_${d.local}`] = d.text;
                    abouts[`about_${d.local}`] = d.text;
                });
                this.setState({about:abouts});
                this.setState({spin:false});
                if(this.formRef.current !== null){
                    this.formRef.current.setFieldsValue(obj);
                }
            }
        });
    };


    componentDidMount() {
        // this.props.getLangs()
        this.getPost()
        this.formRef.current.resetFields()
        this.setState({spin:true});
    }



    saveItem = async (values) => {
        let obj = {
            locales: this.props.langs.map((l) => {
                return {
                    local: l.key,
                    text: values[`about_${l.key}`],
                };
            }),
        };
        await admin
            .post(`public-council`, obj)
            .then((res) => {
                this.props.notify("", true);
                this.formRef.current.resetFields();
                window.history.back();
            })
            .catch((err) => {
                notify(err.response, false);
            });
    };


    render() {
        let { t } = this.props;

        return (
            <div>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                            <div className="page-name">
                                <PicCenterOutlined className="f-20 mr5-15" />
                                <span className="f-20 bold">İctimai şura</span>
                            </div>
                            <Link
                                to={{
                                    pathname: `/employees`,
                                    state: { locales: null },
                                }}
                            >
                                <Button type={"primary"}>{t("cancel")}</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={24}>
                        {(this.state.spin && !this.props.langs) ? (
                            <div className="flex animated fadeIn p-2 bg-white all-center">
                                <Spin size={"large"} />
                            </div>
                        ) : (
                            <div className="p-2 animated edit fadeInUp bg-white">
                                <Form ref={this.formRef} onFinish={this.saveItem} layout="vertical">
                                    <Row gutter={[8, 8]}>
                                        <Col md={24} sm={24} xs={24}>
                                            {this.props.langs.map((l) => (
                                                <div key={l.id} className="form-lang">
                                                    <div className="mb-10">
                                                        {l.name}
                                                    </div>
                                                    <Form.Item
                                                        name={`about_${l.key}`}
                                                        validateTrigger="onChange"
                                                        rules={[noWhitespace(t("inputError"))]}
                                                    >
                                                        <JoditEditor
                                                            ref={this.editor}
                                                            value={this.state.about[`about_${l.key}`]}
                                                            tabIndex={1} // tabIndex of textarea
                                                        />
                                                    </Form.Item>

                                                </div>
                                            ))}
                                        </Col>
                                        <Col xs={24}>
                                            <div className={"flex"}>
                                                <Button className={"mr-15"} htmlType="submit">
                                                    {t("save")}
                                                </Button>
                                                <Link
                                                    to={{
                                                        pathname: `/employees`,
                                                        state: { locales: null },
                                                    }}
                                                >
                                                    <Button type={"primary"}>{t("cancel")}</Button>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        )}
                    </Col>
                </Row>

                <Modal
                    visible={this.state.previewVisible}
                    title={false}
                    footer={null}
                    onCancel={() => {
                        this.setState({previewVisible:false})
                    }}
                >
                    <img alt="example" style={{ width: "100%" }} src={this.state.previewImage} />
                </Modal>
            </div>
        );
    }
}


const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

const exp = withTranslation()(EditPublicCouncil);
export default connect(mapStateToProps, { notify, getLangs })(exp);


