import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  DingtalkOutlined,
  AuditOutlined,
  HomeOutlined,
  ContactsOutlined,
  OrderedListOutlined,
  PictureOutlined,
  FundProjectionScreenOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { logOut, setEdit } from "../../redux/actions";
import history from "../../const/history";

const { SubMenu } = Menu;

const MenuList = (props) => {
  const { t } = useTranslation();
  const [openKeys, setOpenKeys] = useState([]);
  const rootSubmenuKeys = ["10","50", "21", "31", "41", "51", "61"];
  let error =  localStorage.getItem('error')
  const logOut = () => {
    localStorage.removeItem("access_token");
    props.logOut();
    history.push("/");
  };


  const onOpenChange = (openKeysList) => {
    const latestOpenKey = openKeysList.find(
      (key) => openKeys.indexOf(key) === -1
    );
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKeysList);
    } else {
      const opens = latestOpenKey ? [latestOpenKey] : [];
      setOpenKeys(opens);
    }
  };

  useEffect(() => {
    let exp = localStorage.getItem("exp");
    let logtime = localStorage.getItem("now");
    let expDate = parseInt(logtime) + parseInt(exp);
    let time0ut = expDate - Date.now();
    if (time0ut <= 0) {
      logOut();
    }
  }, [Date.now()]);

  return (
    <Menu
      openKeys={openKeys}
      // inlineCollapsed={collapsed}
      mode="inline"
      theme="light"
      onOpenChange={onOpenChange}
      className="menu-ul"
    >
      <SubMenu
          key="10"
          title={
            <span>
            <AuditOutlined />
            <span>{t("admin")}</span>
          </span>
          }
      >
        <Menu.Item key="11">
          <Link to={`/`}>
            <span>Ana səhifə</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="12">
          <Link to={`/news/categories`}>
            <span>Xəbər kateqoriyaları</span>
          </Link>
        </Menu.Item>
        {/*<Menu.Item key="15">*/}
        {/*  <Link to={`/project/categories`}>*/}
        {/*    <span>Layihə kateqoriyaları</span>*/}
        {/*  </Link>*/}
        {/*</Menu.Item>*/}
        <Menu.Item key="16">
          <Link to={`/all/project/categories`}>
            <span>Qarabağ layihə kateqoriyaları</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="17">
          <Link to={`/training/categories`}>
            <span>Təlim kateqoriyaları</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="13">
          <Link to={`/national/standart/categories`}>
            <span>Milli standart kateqoriyaları</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="18.5">
          <Link to={`/process/categories`}>
            <span>Əməliyyat kateqoriyaları</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="14">
          <Link to={`/pages`}>
            <span>Menu və submenular</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="18.3">
          <Link to={`/rules`}>
            <span>Doldurulma qaydaları</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="18">
          <Link to={`/sites`}>
            <span>Dövlət saytları</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="18.1">
          <Link to={`/partners`}>
            <span>Partnyorlar</span>
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
          key="40"
          title={
            <span>
               <HomeOutlined />
              <span>Agentlik</span>
            </span>
          }
      >
        <Menu.Item key="44">
          <Link to={`/about`}>
            <span>Haqqımızda</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="41">
          <Link to={`/history`}>
            <span>Tarixçə</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="42">
          <Link to={`/leadership`}>
            <span>Rəhbərlik</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="43">
          <Link to={`/employees`}>
            <span>İctimai şura</span>
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
          key="70"
          title={
            <span>
               <FundProjectionScreenOutlined />
              <span>Fəaliyyət</span>
            </span>
          }
      >
        <Menu.Item key="71">
          <Link to={`/imsma`}>
            <span>İMSMA</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="72">
          <Link to={`/monitoring`}>
            <span>Monitoring və keyfiyyətə nəzarət</span>
          </Link>
        </Menu.Item>
        
        <SubMenu
            key="73"
            title={
              <span>
              <span>Əməliyyatlar</span>
            </span>
            }
        >
          <Menu.Item key="73.1">
            <Link to={`/processes/icons`}>
              <span>Əməliyyat ikonlar</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="73.2">
            <Link to={`/processes`}>
              <span>Əməliyyat</span>
            </Link>
          </Menu.Item>
        </SubMenu>

        <Menu.Item key="74">
          <Link to={`/trains`}>
            <span>Təlimlər</span>
          </Link>
        </Menu.Item>

        <SubMenu
            key="80"
            title={
              <span>
              <span>Maarifləndirmə işləri</span>
            </span>
            }
        >
          <Menu.Item key="81">
            <Link to={`/trainings`}>
              <span>Təlimlər</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="82">
            <Link to={`/works`}>
              <span>İşlər haqqında</span>
            </Link>
          </Menu.Item>
        </SubMenu>



      </SubMenu>



      <SubMenu
          key="90"
          title={
            <span>
               <ProfileOutlined />
              <span>Layihələr</span>
            </span>
          }
      >
        <Menu.Item key="91">
          <Link to={`/projects/finished`}>
            <span>İcrası bitmiş layihələr</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="92">
          <Link to={`/projects/qarabag`}>
            <span>Qarabağ bütün layihələr</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="93">
          <Link to={`/projects/statistics`}>
            <span>Statistika</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="94">
          <Link to={`/projects/regions`}>
            <span>Qarabağ regionlar</span>
          </Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
          key="21"
          title={
            <span>
            <PictureOutlined />
            <span>Multimedia</span>
          </span>
          }
      >
        <Menu.Item key="22">
          <Link to={`/news`}>
            <span>Xəbərlər</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="23">
          <Link to={`/gallery`}>
            <span>Foto qalereya</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="24">
          <Link to={`/videos`}>
            <span>Video qalereya</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="25">
          <Link to={`/presses`}>
            <span>Press relizler</span>
          </Link>
        </Menu.Item>
      </SubMenu>


      <SubMenu
          key="50"
          title={
            <span>
            <OrderedListOutlined />
            <span>Qanunvericilik</span>
          </span>
          }
      >
        <Menu.Item key="51">
          <Link to={`/national/standart`}>
            <span>Milli standartlar</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="52">
          <Link to={`/legistation`}>
            <span>Qanun</span>
          </Link>
        </Menu.Item>
      </SubMenu>


      <SubMenu
          key="30"
          title={
            <span>
              <ContactsOutlined />
              <span>Əlaqə</span>
            </span>
          }
      >
        <Menu.Item key="31">
          <Link to={`/contact-info`}>
            <span>Əlaqə məlumatları</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="32">
          <Link to={`/vacancies`}>
            <span>Vakansiyalar</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="33">
          <Link to={`/messages`}>
            <span>Mesajlar</span>
          </Link>
        </Menu.Item>
      </SubMenu>

    </Menu>
  );
};

const mapStateToProps = ({ edit }) => {
  return { edit };
};

export default connect(mapStateToProps, { logOut, setEdit })(MenuList);
