import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Input, Row,} from "antd";
import {noWhitespace, whiteSpace} from "../../../../utils/rules";
import {PicCenterOutlined} from "@ant-design/icons";
import MaskedInput from "antd-mask-input";
import {getLangs, notify} from "../../../../redux/actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import admin from "../../../../const/api";
import history from "../../../../const/history";
import {apiRoutes} from "../../../../const/apiroutes";

const EditContact = (props) => {
    let mainUrl = apiRoutes.contact.contactInfo
    const {notify} = props;
    const [id, setId] = useState(undefined);
    const [spin, setSpin] = useState(false);
    const [form] = Form.useForm();
    const {t} = useTranslation();

    const saveItem = (values) => {
        if (id){
            admin
                .put(mainUrl + '/' + id, {
                    ...values
                })
                .then(() => {
                    notify("", true);
                    history.push("/contact-info");
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
        else{
            admin
                .post(mainUrl, {
                    ...values
                })
                .then(() => {
                    notify("", true);
                    history.push("/contact-info");
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };

    const getInfos = () => {
        setSpin(true);
        admin.get(mainUrl).then((res) => {
            setSpin(false);
            if (res.data.content[0]) {
                console.log(res.data.content[0])
                setId(res.data.content[0].id);
                form.setFieldsValue({
                    ...res.data.content[0]
                });
            }
        });
    };



    useEffect(() => {
        getInfos();
    }, [t]);

    return (
        <div>
            <Form form={form} onFinish={saveItem} layout="vertical">
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                            <div className="page-name">
                                <PicCenterOutlined className="f-20 mr5-15"/>
                                <span className="f-20 bold">Əlaqə məlumatları</span>
                            </div>
                            <div>
                                <Button htmlType={"submit"}>{t("save")}</Button>
                                <Link to={"/contact-info"}>
                                    {" "}
                                    <Button type={"primary"}>{t("cancel")}</Button>
                                </Link>
                            </div>
                        </div>
                    </Col>

                    <Col xs={24}>
                        <Card
                            loading={spin}
                            className={"d-block h-100 animated fadeInRight"}
                        >
                            <div className={"border p-2 pb-1"}>
                                <Row gutter={[8, 0]}>
                                    <Col xs={24}>
                                            <div className="form-lang">
                                                <Form.Item
                                                    className="mb-5"
                                                    validateTrigger="onChange"
                                                    name={`address`}
                                                    rules={[whiteSpace(t("inputError"))]}
                                                >
                                                    <Input placeholder={'Address'}/>
                                                </Form.Item>
                                            </div>
                                    </Col>


                                    <Col xs={12}>
                                        <div className="p-2 border">
                                            <div className="flex mb-15  flex-align-center flex-between">
                                                <span>Telefon</span>
                                            </div>
                                            <div  className="mb-10 ">
                                                <Form.Item
                                                    className={"mb-0 w-100 mr-10"}
                                                    validateTrigger="onChange"
                                                    name={"tel"}
                                                    rules={[
                                                        noWhitespace(t("inputError")),
                                                        {
                                                            min: 18,
                                                            message:
                                                                "Əlaqə nömrəsi tam daxil edilməlidir !",
                                                        },
                                                    ]}
                                                >
                                                    <MaskedInput
                                                        className={"mr-10"}
                                                        mask="(+994WW) WWW-WW-WW"
                                                        placeholder="(+99412) 310-23-00"
                                                        {...props}
                                                        formatCharacters={{
                                                            W: {
                                                                validate(char) {
                                                                    return /\w/.test(char);
                                                                },
                                                                transform(char) {
                                                                    return char.toUpperCase();
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>

                                            <div className="flex mb-15  flex-align-center flex-between">
                                                <span>Xəritə</span>
                                            </div>
                                            <div  className="mb-10 ">
                                                    <Form.Item
                                                        className={"mb-10"}
                                                        validateTrigger="onChange"
                                                        name={`lat`}
                                                        rules={[noWhitespace(t("inputError"))]}
                                                    >
                                                        <Input placeholder={"Latitude"} className={"w-100"}/>
                                                    </Form.Item>
                                                    <Form.Item
                                                        className={"mb-10"}
                                                        validateTrigger="onChange"
                                                        name={`lng`}
                                                        rules={[noWhitespace(t("inputError"))]}
                                                    >
                                                        <Input placeholder={"Longitude"} className={"w-100"}/>
                                                    </Form.Item>
                                            </div>




                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="p-2 border">
                                           <span>
                                            <div className="flex mb-15  flex-align-center">
                                              <span>Faks</span>
                                            </div>
                                            <Form.Item
                                                className={"mb-10"}
                                                validateTrigger="onChange"
                                                name={`fax`}
                                                rules={[
                                                    noWhitespace(t("inputError")),
                                                    {
                                                        min: 18,
                                                        message: "Əlaqə nömrəsi tam daxil edilməlidir !",
                                                    },
                                                ]}
                                            >
                                              <MaskedInput
                                                  className={"mr-10"}
                                                  mask="(+994WW) WWW-WW-WW"
                                                  placeholder="(+99412) 310-23-00"
                                                  {...props}
                                                  formatCharacters={{
                                                      W: {
                                                          validate(char) {
                                                              return /\w/.test(char);
                                                          },
                                                          transform(char) {
                                                              return char.toUpperCase();
                                                          },
                                                      },
                                                  }}
                                              />
                                            </Form.Item>
                                          </span>
                                           <span>
                                            <div className="flex mb-15  flex-align-center">
                                              <span>Email</span>
                                            </div>
                                            <Form.Item
                                                className={"mb-10"}
                                                validateTrigger="onSave"
                                                name={`email`}
                                                rules={[
                                                    {
                                                        type: "email",
                                                        message: "Emaili düzgün daxil edin !",
                                                    },
                                                    noWhitespace(t("inputError")),
                                                ]}
                                            >
                                              <Input placeholder={"info@anama.az"}/>
                                            </Form.Item>
                                          </span>





                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};


const mapStateToProps = ({langs}) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, {notify, getLangs})(EditContact);