import React, { useEffect, useState, useRef } from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Switch,
    Spin,
    Form,
    Tooltip,
    Upload,
    Input,
    Popconfirm, Modal,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled, EyeOutlined,
} from "@ant-design/icons";
import { convertColumns } from "../../../../utils/columnconverter";
import { notify } from "../../../../redux/actions";
import { connect } from "react-redux";
import admin from "../../../../const/api";
import { useTranslation } from "react-i18next";
import { whiteSpace } from "../../../../utils/rules";
import {apiRoutes} from "../../../../const/apiroutes";

const Video = (props) => {
    let mainUrl =  apiRoutes.multimedia.videos
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [positions, setPositions] = useState([]);
    const [isActive, setActive] = useState(false);
    const [spin, setSpin] = useState(false);
    const [file , setFile] = useState([])
    const [fileList , setFileList] = useState([])
    const [editing, setEditing] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [previewVisible, setPreviewVisible] = useState(false);
    const cols = [
        { key: "index", value: "#", con: true },
        { key: "name", value: t("name"), con: true },
        { key: "is_active", value: "Status", con: false },
        { key: "id", value: "", con: false },
    ];
    const nameInput = useRef();

    // props
    const { notify } = props;

    const columns = [
        {
            title: "#",
            key: "1",
            dataIndex: "index",
            width: 40,
        },
        {
            title: "",
            dataIndex: "image",
            key: "9",
            width: 40,
            render: (i) => {
                return <img className={"tableImage"} src={i.path} alt="" />;
            },
        },
        {
            title: t("name"),
            key: "2",
            dataIndex: "name",
        },
        {
            title: "",
            key: "2",
            dataIndex: "videoId",
            width: 80,
            render: (i)=>{
                return (
                    <div className={'flex all-center'}>
                        <Tooltip className="ml-5" title={'Videonu izlə'}>
                            <Button type={'primary'} onClick={()=>{ setSelectedId(i)
                                setPreviewVisible(true) }}>
                               <EyeOutlined/>
                            </Button>
                        </Tooltip>
                    </div>
                )
            }
        },
        {
            title: "",
            key: "3",
            dataIndex: "id",
            width: 30,
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deletePosition(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Button
                                className="border-none"
                                type="text"
                                shape="circle"
                                onClick={() => setEditingObject(i)}
                            >
                                <EditFilled />
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const setEditingObject = async (i) => {
        setEditing(i);
        await admin.get(mainUrl , {params: {per_page:20000}}).then((res) => {
            let names = res.data.content.data.find((c) => {
                return c.id === i;
            });
            setFile(names.image_uuid)
            setFileList([
                {
                    uid: names.image_uuid,
                    url: names.image.path,
                }
            ])
            let obj = {};
            names.locales.forEach((name) => {
                obj[`name_${name.local}`] = name.title;
            });
            obj['link'] = names.url
            form.setFieldsValue(obj);
        });
    };

    const cancelEditing = () => {
        setEditing(null);
        setActive(false);
        setFile(null)
        setFileList([])
        form.resetFields();
    };

    const deletePosition = async (i) => {
        await admin
            .delete(`/${mainUrl}/${i}`)
            .then(() => {
                // description
                notify("silindi", true);
                getPositions();
            })
            .catch((err) => {
                //error
                notify(err.response, false);
            });
    };

    const savePosition = async (values) => {
        let obj = {
            image_uuid:file,
            locales: props.langs.map((l, index) => {
                return { local: l.key, title: values[`name_${l.key}`] };
            }),
            url:values.link
        };
        if (!editing) {
            await admin
                .post(mainUrl, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            obj["id"] = editing;
            await admin
                .put(`/${mainUrl}/${editing}`, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };


    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList)
        if (newFileList.length <= 0) {
            setFile(null)
        }
    };


    const  getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }


    const  setUploadFile = ({ onSuccess, onError, file }) => {
        let form_data = new FormData();
        const filename = Math.random(1, 999999) + Date.now() + file.name;
        form_data.append("image_uuid", file, filename);
        admin
            .post(apiRoutes.upload.image, form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                setFile(res.data.content.image__uuid)
                onSuccess(null, file);
            })
            .catch((err) => onError());
    };

    const getPositions = async () => {
        setSpin(true);
        await admin.get(mainUrl , {params: {per_page:20000}}).then((res) => {
            setSpin(false);
            setPositions(
                res.data.content.data.map((p, index) => {
                    return {
                        key: index + 1,
                        ...p,
                        index: index + 1,
                        name: p.locales.find((l) => {
                            return l.local === localStorage.getItem("locale");
                        }).title,
                        videoId: getId(p.url)
                    };
                })
            );
        });
    };


    function getId(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11)
            ? match[2]
            : null;
    }

    useEffect(() => {
        getPositions();
    }, [t]);

    const onSwitchChange = () => {
        setActive(!isActive);
    };

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border animated fadeInDown p-2 mt-0 bg-white">
                    <UnorderedListOutlined className="f-20 mr5-15" />
                    <span className="f-20 bold">Video qalereya</span>
                </div>
            </Col>
            <Col lg={12} xs={24}>
                <Table
                    loading={spin}
                    size="small"
                    className="bg-white animated fadeInLeft"
                    columns={columns}
                    dataSource={convertColumns(positions, cols)}
                    pagination={{
                        pageSize: 10,
                        current_page: 1,
                        total: positions.length,
                    }}
                />
            </Col>
            <Col lg={12} xs={24}>
                <Card title={t("addTo")} className={"animated fadeInRight"}>
                    <Form layout="vertical" onFinish={savePosition} form={form}>
                        <p className="mb-5">Şəkil yüklə</p>
                        <Form.Item
                            className={'animated fadeIn'}
                            validateTrigger="onChange"
                            name={`photo`}
                            required
                        >
                            {/*<ImgCrop*/}
                            {/*    className={"w-100"}*/}
                            {/*    rotate*/}
                            {/*    aspect={16 / 9}*/}
                            {/*    grid*/}
                            {/*>*/}
                            <Upload
                                accept=".png, .jpg , .jpeg"
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onChange}
                                onPreview={false}
                                customRequest={setUploadFile}
                                beforeUpload={null}
                            >
                                {fileList.length < 1 && "+ Yüklə"}
                            </Upload>
                            {/*</ImgCrop>*/}
                        </Form.Item>

                        <p className="mb-5">Adı</p>
                        {props.langs ? (
                            props.langs.map((l, i) => {
                                return (
                                    <div key={l.id} className="form-lang">
                                        <Form.Item
                                            className="mb-5"
                                            validateTrigger="onChange"
                                            name={`name_${l.key}`}
                                            rules={[whiteSpace(t("inputError"))]}
                                        >
                                            <Input ref={nameInput} />
                                        </Form.Item>
                                        <div className="input-lang">{l.key}</div>
                                    </div>
                                );
                            })
                        ) : (
                            <Spin size={"large"} />
                        )}

                        <Form.Item
                            className="mb-5"
                            label={'Youtube linki'}
                            validateTrigger="onChange"
                            name={`link`}
                            rules={[whiteSpace(t("inputError"))]}
                        >
                            <Input />
                        </Form.Item>


                        <div className="flex  flex-between mt-15">
                            <Button onClick={cancelEditing}>{t("cancel")}</Button>
                            <Button htmlType="submit">{t("save")}</Button>
                        </div>
                    </Form>
                </Card>
            </Col>

            <Modal
                visible={previewVisible}
                title={false}
                footer={null}
                onCancel={() => {
                    setPreviewVisible(false);
                }}
            >
                {(previewVisible && selectedId) ?
                    <iframe style={{height:'400px'}} className={'w-100'} src={`https://www.youtube.com/embed/${selectedId}`}
                            frameBorder='0'
                            allow='autoplay; encrypted-media'
                            allowFullScreen
                            title='video'
                    /> : ''
                }
            </Modal>

        </Row>
    );
};

const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, { notify })(Video);