import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import Home from "../../Pages/Admin/Home/Home";
import About from "../../Pages/Company/About/About";

import History from '../../Pages/Company/History/History';
import EditHistoryContent from '../../Pages/Company/History/EditHistoryContent';
import HistoryContent from '../../Pages/Company/History/HistoryContent';
import Pages from "../../Pages/Admin/Pages/Pages"
import NewsCategory from "../../Pages/Admin/NewsCategory/NewsCategory";
import NationalStandartsCategory from "../../Pages/Admin/NationalStandartsCategory/NationalStandartsCategory";
import News from "../../Pages/Multimedia/News/News";
import EditNews from "../../Pages/Multimedia/News/EditNews";
import EditNationalSt from "../../Pages/Legistations/NationalStandarts/EditNationalSt";
import NationalStandarts from "../../Pages/Legistations/NationalStandarts/NationalStandarts";
import Legistation from "../../Pages/Legistations/Legistation/Legistation";
import Gallery from "../../Pages/Multimedia/Gallery/Gallery";
import Full from "../../Pages/Multimedia/Gallery/Full";
import EditGallery from "../../Pages/Multimedia/Gallery/EditGallery";
import Video from "../../Pages/Multimedia/Video/Video";
import ProjectCategory from "../../Pages/Admin/ProjectCategory/ProjectCategory";
import QarabakhCategory from "../../Pages/Admin/QarabakhCategory/QarabakhCategory";
import TrainingCategory from "../../Pages/Admin/TrainingCategory/TrainingCategory";
import PressReliese from "../../Pages/Multimedia/PressReliese/PressReliese";
import CountrySites from "../../Pages/Admin/CountrySites/CountrySites";
import Partners from "../../Pages/Admin/Partners/Partners";
import LeaderShip from "../../Pages/Company/LeaderShip/LeaderShip";
import EditLeaderShip from "../../Pages/Company/LeaderShip/EditLeaderShip";
import EditEmployees from "../../Pages/Company/Employees/EditEmployees";
import Employees from "../../Pages/Company/Employees/Employees";
import Imsmas from "../../Pages/Activity/Imsmas/Imsmas";
import Monitoring from "../../Pages/Activity/Monitoring/Monitoring";
import Rules from "../../Pages/Admin/Rules/Rules";
import WorkAbouts from "../../Pages/Activity/WorkAbouts/WorkAbouts";
import Trainings from "../../Pages/Activity/Tranings/Tranings";
import ProcessIcons from "../../Pages/Activity/ProcessIcons/ProcessIcons";
import Process from "../../Pages/Activity/Process/Process";
import FinishedProject from "../../Pages/Projects/FinishedProjects/FinishedProject";
import Karabach from "../../Pages/Projects/Karabach/Karabach";
import Vacancies from "../../Pages/Contact/Vacancies/Vacancies";
import ProcessCategories from "../../Pages/Admin/ProcessCategories/ProcessCategories";
import Messages from "../../Pages/Contact/Messages/Messages";
import ContactInfos from "../../Pages/Contact/ContactInfos/ContactInfos";
import EditContactInfo from "../../Pages/Contact/ContactInfos/EditContactInfo";
import Statistics from "../../Pages/Projects/Statistics/Statistics";
import PublicCouncil from "../../Pages/Company/PublicCouncil/PublicCouncil";
import EditPublicCouncil from "../../Pages/Company/PublicCouncil/EditPublicCouncil";
import EditStatistics from "../../Pages/Projects/Regions/EditStatistics";
import Regions from "../../Pages/Projects/Regions/Regions";
import RegionStatistics from "../../Pages/Projects/Regions/RegionStatistics";
import Trains from '../../Pages/Activity/Trains/Trains';
function Routes(props) {
    return (
        <Switch>
            <Route exact path={`/`} component={Home} />

            <Route exact path={`/contact-info`} component={ContactInfos} />
            <Route exact path={`/contact-info/edit`} component={EditContactInfo} />
            <Route exact path={`/pages`} component={Pages} />
            <Route exact path={`/sites`} component={CountrySites} />
            <Route exact path={`/partners`} component={Partners} />
            <Route exact path={`/processes`} component={Process} />
            <Route exact path={`/trains`} component={Trains} />
            <Route exact path={`/imsma`} component={Imsmas} />
            <Route exact path={`/about`} component={About} />
            <Route exact path={`/rules`} component={Rules} />
            <Route exact path={`/messages`} component={Messages} />
            <Route exact path={`/monitoring`} component={Monitoring} />
            <Route exact path={`/trainings`} component={Trainings} />
            <Route exact path={`/projects/finished`} component={ProjectCategory} />
            <Route exact path={`/projects/finished/:id`} component={FinishedProject} />
            <Route exact path={`/projects/qarabag`} component={Karabach} />
            <Route exact path={`/processes/icons`} component={ProcessIcons} />
            <Route exact path={`/works`} component={WorkAbouts} />
            <Route exact path={`/vacancies`} component={Vacancies} />

            <Route exact path={`/project/categories`} component={ProjectCategory} />
            <Route exact path={`/process/categories`} component={ProcessCategories} />

                <Route exact path={`/projects/statistics`} component={Statistics} />
                {/*<Route exact path={`/statistics/edit/:id`} component={EditStatistics} />*/}
                {/*<Route exact path={`/statistics/create`} component={EditStatistics} />*/}

            <Route exact path={`/training/categories`} component={TrainingCategory} />



            <Route exact path={`/all/project/categories`} component={QarabakhCategory} />




            <Route exact path={`/news/categories`} component={NewsCategory} />
            <Route exact path={`/news`} component={News} />
            <Route exact path={`/news/edit/:id`} component={EditNews} />
            <Route exact path={`/news/create`} component={EditNews} />


            <Route exact path={`/national/standart/categories`} component={NationalStandartsCategory} />
            <Route exact path={`/national/standart`} component={NationalStandarts} />
            <Route exact path={`/national/standart/:id`} component={EditNationalSt} />
            <Route exact path={`/national/standart/create`} component={EditNationalSt} />



            <Route exact path={`/leadership`} component={LeaderShip} />
            <Route exact path={`/leadership/edit/:id`} component={EditLeaderShip} />
            <Route exact path={`/leadership/create`} component={EditLeaderShip} />

            <Route exact path={`/employees`} component={PublicCouncil} />
            <Route exact path={`/employees/edit`} component={EditPublicCouncil} />
            <Route exact path={`/employees/create`} component={EditPublicCouncil} />

            <Route exact path={`/gallery`} component={Gallery} />
            <Route exact path={`/gallery/view/:id`} component={Full} />
            <Route exact path={`/gallery/edit/:id`} component={EditGallery} />
            <Route exact path={`/gallery/create`} component={EditGallery} />


            <Route exact path={`/videos`} component={Video} />
            <Route exact path={`/presses`} component={PressReliese} />

            <Route exact path={`/legistation`} component={Legistation} />


            <Route exact path={`/history`} component={History} />
            <Route exact path={`/history/:year`} component={HistoryContent} />
            <Route exact path={`/history/:year/edit/:id`} component={EditHistoryContent} />
            <Route exact path={`/history/:year/create`} component={EditHistoryContent} />

            <Route exact path={`/projects/regions`} component={Regions} />
            <Route exact path={`/projects/regions/:regionId`} component={RegionStatistics} />
            <Route exact path={`/projects/regions/:regionId/edit/:id`} component={EditStatistics} />
            <Route exact path={`/projects/regions/:regionId/create`} component={EditStatistics} />

            <Redirect to="/" />
            <Route path="/">
                <p className="flex all-center h-100vh">Not found</p>
            </Route>
        </Switch>
    );
}

export default Routes;
