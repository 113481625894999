import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "./map.css";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet-defaulticon-compatibility";

function GoogleM(props) {
  return (
      <>
          <MapContainer center={props.map} zoom={22} >
              <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker dragable={true} position={props.map}>
                  <Popup>
                      {props.address}
                  </Popup>
              </Marker>
          </MapContainer>
      </>
  );

}

export default GoogleM;