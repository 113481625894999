export const apiRoutes = {
    upload:{
      image:'image',
      file:'file',
      video:'video'
    },
    admin:{
        login:'login',
        logout:'logout',
        pages:'pages',
        partners:'partners',
        sites:'country/sites',
        subPages:'sub/pages',
        trainingCategories:'training/categories',
        allProjectCategories:'all/project/categories',
        projectCategory:'project/categories',
        newsCategories:'news/categories',
        processCategories:'processes-category',
        nationalStandartsCategories:'national/standart/categories',
        rules:'rules',
    },
    about:{
      abouts:'abouts',
      categories: 'about/categories'
    },
    agency:{
        about: 'about-action',
        leaderShip:'leaderships',
        employees:'employees',
    },
    activity:{
        imsmas:'imsmas',
        processesIcons:'processesIcons',
        processes:'processes',
        trains: 'doctrines',
        qualities:'qualities',
        trainings:'trainings',
        workAbouts:'work/abouts'
    },
    projects:{
        finishedProjects:'projects',
        karabachAllProjects:'all/projects',
        statistics:'statistics',
        generalStatistics:'generalStatistics',
        regions:'regions'
    },
    legistations:{
        legislation:'legislations'
    },
    posts:{
      news:'news',
      nationalStandarts:'national/standarts'
    },
    contact:{
        main:'Contact',
        contactInfo:'contacts',
        vacancies:'vacancies',
        contactForms:'contact/forms',
        socialNetwork:'SosialNetwork'
    },
    multimedia:{
        photos:'photos',
        photoFolder:'photo/folders',
        banner:'banners',
        homeVideo:'home/videos',
        videos:'videos',
        presses:'presses'
    }
}